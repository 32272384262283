<template>
  <div class="page-body-box">
    <page-main back :title="primaryText + typeStr + name + descText">
      <div class="table-title-box">
        <template>
          <el-tabs v-model="activeName" @tab-click="handleClick">
            <el-tab-pane label="全部明细" name="" />
            <el-tab-pane label="收入明细" name="1" />
            <el-tab-pane label="支出明细" name="2" />
          </el-tabs>
        </template>
        <div v-if="(startDate&&endDate)" class="time-info">
          <span>数据截取：</span>
          <span>{{ startDate }}</span>
          <span> — </span>
          <span>{{ endDate }}</span>
        </div>
      </div>

      <template v-if="type==='gredit'">
        <el-table
          ref="tableRef"
          v-loading="loading"
          :data="cashDetailTable"
          style="width: 100%;"
        >
          <el-table-column
            :key="Math.random()"
            label="时间"
            min-width="100"
            align="left"
            prop="createdAt"
            fixed
          >
            <template slot-scope="scope">
              <span>{{ scope.row.createdAt || "—" }}</span>
            </template>
          </el-table-column>
          <el-table-column
            :key="Math.random()"
            label="收款方"
            show-overflow-tooltip
            min-width="100"
            align="center"
            prop="receiver"
          >
            <template slot-scope="scope">
              <span>{{ scope.row.receiver || "—" }}</span>
            </template>
          </el-table-column>
          <el-table-column
            :key="Math.random()"
            label="积分"
            align="center"
            prop="money"
          >
            <template slot-scope="scope">
              <span>{{ scope.row.money || "0" }}</span>
            </template>
          </el-table-column>
          <el-table-column
            :key="Math.random()"
            label="收入/支出"
            align="center"
            prop="changeType"
          >
            <template slot-scope="scope">
              <div>
                {{ scope.row.changeType | changeTypeStr }}
              </div>
            </template>
          </el-table-column>
          <el-table-column
            :key="Math.random()"
            prop="typeStr"
            align="center"
          >
            <!-- eslint-disable-next-line -->
            <template slot="header" slot-scope="scope">
              <el-select :value="searchData.searchType" placeholder="类型" @change="onSelectChange">
                <el-option
                  v-for="item in options"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                />
              </el-select>
            </template>
            <template slot-scope="scope">
              <div>
                {{ scope.row.typeStr || '—' }}
              </div>
            </template>
          </el-table-column>
          <el-table-column
            :key="Math.random()"
            :show-overflow-tooltip="true"
            min-width="100"
            label="类型描述"
            align="center"
            prop="title"
          >
            <template slot-scope="scope">
              <span>{{ scope.row.title || "—" }}</span>
            </template>
          </el-table-column>
          <el-table-column
            :key="Math.random()"
            label="细节描述"
            :show-overflow-tooltip="true"
            min-width="100"
            align="center"
            prop="actionDetail"
          >
            <template slot-scope="scope">
              <span>{{ scope.row.actionDetail || "—" }}</span>
            </template>
          </el-table-column>
          <el-table-column
            v-if="activeName==0"
            :key="Math.random()"
            label="实时结余"
            :show-overflow-tooltip="true"
            min-width="100"
            align="center"
            prop="snapshotFinancialAmount"
          >
            <template slot-scope="scope">
              <span>{{ scope.row.snapshotFinancialAmount === null ? '-' :scope.row.snapshotFinancialAmount }}</span>
            </template>
          </el-table-column>
          <el-table-column
            :key="Math.random()"
            label="打款方"
            show-overflow-tooltip
            align="center"
            prop="remitter"
          >
            <template slot-scope="scope">
              <span>{{ scope.row.remitter || "—" }}</span>
            </template>
          </el-table-column>
        </el-table>
      </template>
      <template v-else>
        <el-table
          v-loading="loading"
          :data="cashDetailTable"
          style="width: 100%;"
        >
          <el-table-column
            label="时间"
            min-width="100"
            align="left"
            prop="createdAt"
            fixed
          >
            <template slot-scope="scope">
              <span>{{ scope.row.createdAt || "—" }}</span>
            </template>
          </el-table-column>
          <el-table-column
            label="收款方"
            show-overflow-tooltip
            min-width="100"
            align="center"
            prop="receiver"
          >
            <template slot-scope="scope">
              <span>{{ scope.row.receiver || "—" }}</span>
            </template>
          </el-table-column>
          <el-table-column
            label="金额"
            align="center"
            prop="money"
          >
            <template slot-scope="scope">
              <span>{{ scope.row.money || "0" }}</span>
            </template>
          </el-table-column>
          <el-table-column
            label="收入/支出"
            align="center"
            prop="changeType"
          >
            <template slot-scope="scope">
              <div>
                {{ scope.row.changeType | changeTypeStr }}
              </div>
            </template>
          </el-table-column>
          <el-table-column
            prop="typeStr"
            align="center"
          >
            <!-- eslint-disable-next-line -->
            <template slot="header" slot-scope="scope">
              <el-select :value="searchData.searchType" placeholder="类型" @change="onSelectChange">
                <el-option
                  v-for="item in options"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                />
              </el-select>
            </template>
            <template slot-scope="scope">
              <div>
                {{ scope.row.typeStr || '—' }}
              </div>
            </template>
          </el-table-column>
          <el-table-column
            :show-overflow-tooltip="true"
            min-width="100"
            label="类型描述"
            align="center"
            prop="title"
          >
            <template slot-scope="scope">
              <span>{{ scope.row.title || "—" }}</span>
            </template>
          </el-table-column>
          <el-table-column
            label="开始时间"
            :show-overflow-tooltip="true"
            min-width="100"
            align="center"
            prop="startAt"
          >
            <template slot-scope="scope">
              <span>{{ scope.row.startAt || "—" }}</span>
            </template>
          </el-table-column>
          <el-table-column
            label="结束时间"
            :show-overflow-tooltip="true"
            min-width="100"
            align="center"
            prop="finishAt"
          >
            <template slot-scope="scope">
              <span>{{ scope.row.finishAt || "—" }}</span>
            </template>
          </el-table-column>
          <el-table-column
            v-if="activeName==0"
            :key="Math.random()"
            label="实时结余"
            :show-overflow-tooltip="true"
            min-width="100"
            align="center"
            prop="snapshotFinancialAmount"
          >
            <template slot-scope="scope">
              <span>{{ scope.row.snapshotFinancialAmount === null ? '—' :scope.row.snapshotFinancialAmount }}</span>
            </template>
          </el-table-column>
          <el-table-column
            label="打款方"
            show-overflow-tooltip
            align="center"
            prop="remitter"
          >
            <template slot-scope="scope">
              <span>{{ scope.row.remitter || "—" }}</span>
            </template>
          </el-table-column>
        </el-table>
      </template>
      <el-pagination
        class="sorter-box"
        :current-page="searchData.pageNo"
        :page-size="searchData.pageSize"
        :total="total"
        background
        layout="total, prev, pager, next, jumper"
        @current-change="onCurrentChange"
      />
    </page-main>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

const changeTypeMap = new Map([
  [1, '收入'],
  ['1', '收入'],
  [2, '支出'],
  ['2', '支出']
])
export default {
  name: 'PaymentDetail',
  filters: {
    changeTypeStr(type) {
      return changeTypeMap.get(type) || '—'
    }
  },

  data() {
    return {
      primaryText: '财务管理 - ',
      descText: '账户收支明细',
      typeStr: '',
      // ------------------ tabList ----------------
      activeName: '',

      // ------------------ table header select ----------------
      options: [],
      optionsAll: [{
        value: '',
        label: '全部'
      }],
      optionsSpend: [],    // 支出Tab
      optionsEarn: [],   // 收入Tab
      // -------------------- 搜索、页数 -----------------
      loading: true,
      id: '',
      type: '',
      startDate: '',
      endDate: '',
      name: '',
      timerId: null,
      total: 0,
      searchData: {
        startAt: '',
        endAt: '',
        pageNo: 1,
        pageSize: 10,
        targetOrgId: '',
        detailType: '',
        searchType: '',
        flowType: ''
      }
    }
  },
  computed: {
    ...mapGetters(['cashDetailTable']),
    initSearchData() {
      return JSON.stringify(this.searchData)
    }
  },
  watch: {
    initSearchData: {
      handler(newVal, oldVal) {
        if (JSON.parse(newVal).pageNo === JSON.parse(oldVal).pageNo) {
          this.$set(this.searchData, 'pageNo', 1)
        }
        clearTimeout(this.timerId)
        this.timerId = setTimeout(() => {
          this.onGetCashDetailTable()
        }, 500)
      },
      deep: true
    }
  },

  beforeUpdate() {
    this.$nextTick(() => {
      // 处理el-table 与el-tab 结合切换引起的抖动问题
      this.type === 'gredit' && this.$refs['tableRef'].doLayout()
    })
  },
  mounted() {
    this.onPageInit()
  },
  methods: {
    ...mapActions(['getCashDetailTable']),
    onPageInit() {
      this.id = this.$route.params.id
      this.type = this.$route.params.type
      this.startDate = this.$route.query.startDate
      this.endDate = this.$route.query.endDate
      this.name = this.$route.query.name || ''
      this.searchData = Object.assign({}, this.searchData, {
        startAt: this.startDate,
        endAt: this.endDate,
        targetOrgId: this.id,
        flowType: this.type === 'gredit' ? '1' : '2'
      })
      if (this.type === 'gredit') {
        this.typeStr = '积分账户 - '
        this.optionsSpend = [
          {
            value: '14',
            label: '委托调研'
          },
          {
            value: '5',
            label: '转账支出'
          },
          {
            value: '7',
            label: '补贴支出'
          },
          {
            value: '11',
            label: '培训支出'
          }
        ]
        this.optionsEarn = [
          {
            value: '8',
            label: '充值'
          },
          {
            value: '6',
            label: '转账'
          },
          // {
          //   value: '10',
          //   label: '未认证医生退回'
          // },
          {
            value: '9999',
            label: '其它收入'
          }
        ]
      } else {
        this.typeStr = '视频采购账户 - '
        this.optionsSpend = [
          {
            value: '4',
            label: '直播讲课'
          },
          {
            value: '9',
            label: '会议讲课'
          },
          {
            value: '15',
            label: '医学播课'
          },
          {
            value: '5',
            label: '转账支出'
          }
        ]
        this.optionsEarn = [
          {
            value: '8',
            label: '充值收入'
          },
          {
            value: '6',
            label: '转账收入'
          },
          {
            value: '9999',
            label: '其它收入'
          }
        ]
      }
      this.options = [].concat(this.optionsAll, this.optionsSpend, this.optionsEarn)
    },
    onGetCashDetailTable() {
      this.loading = true
      this.getCashDetailTable(Object.assign({}, this.searchData, {searchType: this.searchData.searchType ? Number(this.searchData.searchType) : ''}))
        .then(res => {
          this.total = res.data.totalCount || 0
          this.loading = false
        }, () => {
          this.total = 0
          this.loading = false
        })
    },
    handleClick(options = {name: ''}) {
      if (options.name === '1') {
        this.options = [].concat(this.optionsAll, this.optionsEarn)
      } else if (options.name === '2') {
        this.options = [].concat(this.optionsAll, this.optionsSpend)
      } else {
        this.options = [].concat(this.optionsAll, this.optionsSpend, this.optionsEarn)
      }
      if (this.searchData.pageNo === 1 && this.searchData.detailType === options.name) {
        this.onGetCashDetailTable()
      }
      this.searchData = Object.assign({}, this.searchData, {pageNo: 1, detailType: options.name, searchType: ''})
    },
    onSelectChange(val) {
      if (this.searchData.pageNo === 1 && this.searchData.searchType === val) {
        this.onGetCashDetailTable()
      }
      this.searchData = Object.assign({}, this.searchData, {searchType: val, pageNo: 1})
    },
    onCurrentChange(options) {
      this.$set(this.searchData, 'pageNo', options)
    }
  }

}
</script>
<style scoped lang="scss">
  .table-title-box {
    position: relative;
    z-index: 100;
    margin-top: -10px;
  }
  .time-info {
    position: absolute;
    right: 0;
    top: 20px;
    z-index: 1000;
    color: #7784a1;
    @media screen and (max-width: 768px) {
      // 换行展示
      position: static;
      line-height: 20px;
    }
  }
  .sorter-box {
    padding: 30px;
    text-align: center;
    background: #fff;
  }
</style>
